<template>
  <main
    id="main-content"
    :class="[classes, backgroundColorClasses]"
    :style="[backgroundColorStyles, pageHeaderForegroundColor, pageHeaderBackgroundColor]"
  >
    <Toast
      v-for="toast in toasts"
      :key="toast._uid"
      v-editable="data"
      :cookie-id="toast._uid"
      v-bind="toast"
    />

    <component
      :is="component"
      :id="id"
      :url="url"
      :language-code="$store.state.frontend.currentLanguageCode"
      :data="data"
    />

    <portal-target
      name="underscore"
      slim
    />
    <portal-target
      name="sticky"
      slim
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Default from '@made-people/centra-storyblok-nuxt-shared/lib/pages/_'

export default {
  mixins: [Default],
  data() {
    return {
      backgroundColorClasses: [],
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$store.state.frontend.currentLanguageCode,
      },
    }
  },
  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
    }),
    page() {
      if (this.component === 'storyblok') return this.$store.getters['storyblok/getStoryById'](this.id)
      return null
    },
    pageBackgroundColor() {
      return this.page?.content?.backgroundColor
    },
    defaultBackgroundColorHex() {
      return this.settings?.defaultBackgroundColorHex?.color || this.settings?.defaultBackgroundColorHex
    },
    isPageBackgroundColorDefault() {
      return this.pageBackgroundColor === 'grey-lighter'
    },
    // only apply hex color background if page has default background color
    backgroundColorStyles() {
      return this.defaultBackgroundColorHex && this.isPageBackgroundColorDefault
        ? {
            backgroundColor: this.defaultBackgroundColorHex,
          }
        : {}
    },
    pageHeaderBackgroundColor() {
      // Returned from the native-color-picker component
      const colorPickerHex = this.page?.content?.headerBackgroundColorHex?.color
      // Returned from text input field
      const textInputHex = this.page?.content?.headerBackgroundColorHex
      const bgHex = colorPickerHex || typeof textInputHex === 'string' ? textInputHex : null
      return bgHex ? { '--header-bgcolor-hex': bgHex } : {}
    },
    pageHeaderForegroundColor() {
      // Returned from the native-color-picker component
      const colorPickerHex = this.page?.content?.headerColorHex?.color
      // Returned from text input field
      const textInputHex = this.page?.content?.headerColorHex
      const fgHex = colorPickerHex || typeof textInputHex === 'string' ? textInputHex : null
      return fgHex ? { '--header-fgcolor-hex': fgHex } : {}
    },
  },
  created() {
    this.setPdpBackground()
    this.setPlpBackground()
  },
  methods: {
    setPdpBackground() {
      const colorName = this.settings?.backgroundColorPdp
      const isCentraPdp = this?.component === 'product'
      const isStoryblokPdp = this.data?.content?.component === 'ProductDetailPage'
      if (colorName && (isCentraPdp || isStoryblokPdp)) {
        this.backgroundColorClasses.push(`bg-color--${colorName}`)
      }
    },
    setPlpBackground() {
      const colorName = this.settings?.backgroundColorPlp
      const isPlp = this?.component === 'category'
      const isSearch = this?.component === 'search'
      if (colorName && (isPlp || isSearch)) {
        this.backgroundColorClasses.push(`bg-color--${colorName}`)
      }
    },
  },
  serverPrefetch() {
    // This is allowed if we're behind fastly or cloudflare
    if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader('cache-control', `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=600, stale-while-error=86400`)
    }
  },
}
</script>
